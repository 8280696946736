<template>
    <UIGridContainer>
        <UIGridRow>
            <UIGridCol cols="12" sm="12" md="8" lg="8">
                <div class="flex-grow-1 d-flex flex-column justify-content-start mb-4">
                    <ContentTitle :content="brand.title" :params="{ value: ' ' }" class="mb-0 mt-sm-0" />
                    <div>
                        <div>
                            <ThemeSlogan></ThemeSlogan>
                        </div>
                        <div v-if="showProductReviews" class="product-rating-wrapper mt-1 mr-2">
                            <UIRatingStars :rating="reviews.rating"></UIRatingStars>

                            <UIIcon name="circle" class="mx-2 seperation-icon"></UIIcon>
                            <span>{{ reviews.rating }}/5</span>
                            <UIIcon name="circle" class="mx-2 seperation-icon"></UIIcon>
                            <a
                                href="#reviews"
                                style="white-space: nowrap; overflow: visible"
                                @click.stop.prevent="scrollToReviews"
                                >{{ translate('giftcard.showAllReviews') }}</a
                            >
                        </div>
                    </div>
                </div>
            </UIGridCol>

            <!-- Testing introtext only on/for MOBILE
                            paysafecard
                            Region: DE, NL, FR
                            ISO: de-de, de-en, nl-nl, nl-en, fr-fr, fr-en -->
            <UIGridCol
                v-if="
                    brand.slug === 'paysafecard' &&
                    ($locale.regio === 'DE' || $locale.regio === 'NL' || $locale.regio === 'FR')
                "
                cols="12"
                class="d-md-none"
            >
                <div v-if="$locale.language === 'de'" class="mb-4">
                    Mit einer paysafecard kaufen Sie einen Code, den Sie sofort als Online Zahlungsmittel verwenden
                    können. So bezahlen Sie sicher und risikofrei im Internet.
                </div>
                <div v-if="$locale.language === 'en'" class="mb-4">
                    Use a paysafecard to pay easily and safely on thousands of sites. Works instantly and ensures
                    optimal protection of your private details and banking information.
                </div>
                <div v-if="$locale.language === 'fr'" class="mb-4">
                    Avec une recharge paysafecard, payez en ligne de manière sûre et anonyme comme avec des espèces.
                    Utilisez-la directement pour payer facilement sur des milliers de sites.
                </div>
                <div v-if="$locale.language === 'nl'" class="mb-4">
                    Met een paysafecard betaal je gemakkelijk en veilig op duizenden sites. Werkt direct en zorgt voor
                    optimale bescherming van je privé- en bankgegevens.
                </div>
            </UIGridCol>

            <UIGridCol v-show="!hideForMicrosoftProduct" v-if="allowsCrossBorder" cols="12" sm="12" md="4" lg="4">
                <!-- used a v-show to prevent mismatching serverside nodes -->
                <div class="select-container tooltip-hover mb-4">
                    <div class="d-flex flex-row small">
                        <span v-if="regionProduct" class="ml-auto text-light">{{ translate('country.info') }}</span>
                        <span v-if="currencyProduct" class="ml-auto text-light">{{ translate('currency.info') }}</span>
                    </div>
                    <ProductDropdown v-if="!isGlobal" :brand="brand" class="w-100" @select="selectCountryOrCurrency">
                        <template #dropdown-label>
                            <span v-if="regionProduct">{{ translate('country.info') }}</span>
                            <span v-if="currencyProduct">{{ translate('currency.info') }}</span>
                        </template>
                    </ProductDropdown>
                    <span v-if="regionProduct" class="tooltip-message">{{ translate('country.tooltip') }}</span>
                    <span v-if="currencyProduct" class="tooltip-message">{{ translate('currency.tooltip') }}</span>
                </div>
                <div class="clearfix"></div>
            </UIGridCol>
        </UIGridRow>
    </UIGridContainer>
</template>

<script>
import { UIRatingStars, UIGridCol, UIGridRow, UIIcon, UIGridContainer } from '@dundle/ui/components';

import SecurityMixin from '~/mixins/SecurityMixin';
import TranslationMixin from '~/mixins/TranslationMixin';
import { ContentTitle } from '~/components/content';
import { ThemeSlogan } from '~/components/theme';
import ProductDropdown from '~/components/product/ProductDropdown';
export default {
    components: {
        ProductDropdown,
        UIRatingStars,
        ThemeSlogan,
        UIIcon,
        UIGridCol,
        UIGridRow,
        UIGridContainer,
        ContentTitle,
    },
    mixins: [SecurityMixin, TranslationMixin],
    props: ['brand', 'products', 'reviews'],
    computed: {
        currencyProduct() {
            return this.brand.selectedCurrency && this.brand.currencyCodes && this.brand.currencyCodes.length > 1;
        },
        regionProduct() {
            return this.brand.selectedRegion && this.brand.regionCodes && this.brand.regionCodes.length > 1;
        },
        showProductReviews() {
            return this.reviews.rating >= 4;
        },
        isGlobal() {
            return (
                (this.brand.selectedRegion &&
                    this.brand.regionCodes.length === 1 &&
                    this.brand.regionCodes[0] === 'ww') ||
                (this.products[0] &&
                    this.brand.selectedCurrency &&
                    this.brand.currencyCodes.length <= 1 &&
                    !this.products[0].currencyLocked)
            );
        },
    },
    methods: {
        scrollToReviews() {
            document.getElementById('trustpilot-reviews').scrollIntoView({ behavior: 'smooth', block: 'start' });
            this.$analytics?.event('brand_show_reviews');
        },
        selectCountryOrCurrency(selection) {
            this.$analytics.event('brand_change_country_or_currency');
            if (this.brand.selectedRegion) {
                this.$router.push({ query: { c: selection.toLowerCase() } });
            } else if (this.brand.selectedCurrency) {
                this.$router.push({ query: { c: selection.toLowerCase() } });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.product-rating-wrapper {
    display: flex;
    align-items: center;
    span {
        color: $color-grey-5;
    }
    .seperation-icon {
        margin-left: 0.25rem !important;
        margin-right: 0.25rem !important;
        font-size: 0.4rem;
        color: #e0e0e0;
    }
}

@media screen and (max-width: 1025px) {
    .tooltip-message {
        display: none;
    }
}
.tooltip-hover {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    &:hover {
        .tooltip-message {
            visibility: visible;
            opacity: 1;
        }
    }
    .tooltip-message {
        visibility: hidden;
        position: absolute;
        width: 200px;
        background-color: $color-grey-6;
        color: $color-text-inverted;
        text-align: left;
        padding: 10px;
        border-radius: $border-radius-default;
        z-index: 5;
        opacity: 0;
        transition: opacity 0.6s;
        top: -30%;
        right: 105%;
        &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 100%;
            margin-top: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: transparent transparent transparent $color-grey-6;
        }
    }
}
</style>
<i18n src="@dundle/locale/data/giftcard.json"></i18n>
<i18n>{}</i18n>
